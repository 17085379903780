@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .checkbox-input {
    @apply text-primary-500 focus:ring-offset-0 focus:ring focus:outline-none outline-0 focus:ring-primary-500 focus:ring-opacity-25 border-2 border-primary-500 rounded-[0.25em] w-[1.1em] h-[1.1em] active:brightness-90 transition duration-200 ease-in-out cursor-pointer;
  }

  .calendar-select {
    @apply bg-white border border-gray-300 text-gray-700 py-2 px-4 block w-full rounded-md focus:border-primary-500 focus:outline-none focus:ring-primary-500 focus:ring-opacity-50 focus:ring-2 transition duration-100;
  }

  .radio-input {
    @apply text-primary-500 border-2 border-gray-300 w-[1.1em] h-[1.1em] rounded-full focus:ring focus:outline-none outline-0 focus:ring-primary-500 focus:ring-opacity-25 focus:ring-offset-0 active:brightness-90 transition duration-300 ease-in-out;
  }

  .search-start {
    @apply fixed top-[45%] py-4 px-2 left-[454px] -translate-y-1/2 bg-primary text-gray-100 rounded-r-sm cursor-pointer;
    /*background-image: theme("backgroundImage.primary-gradient");*/
  }

  .search-button {
    @apply rounded-md bg-primary-gradient text-white outline-none transition duration-300 ease-in-out hover:scale-110 hover:bg-primary-gradient-hover active:scale-105 active:bg-primary-gradient-active shadow-xl;
  }

  isDrawingEnd .search-button-disabled {
    @apply opacity-30 cursor-not-allowed pointer-events-none;
  }

  .search-button-disabled {
    @apply opacity-30 cursor-not-allowed pointer-events-none;
  }
}

@layer utilities {
  .tab-link {
    @apply inline-flex shrink-0 items-center gap-2 border-b-2 border-transparent px-1 text-sm font-medium text-palette-gray hover:border-gray-300 transition duration-100 ease-in flex-1 justify-center;
  }

  .tab-link-active,
  .notification-tab-link {
    @apply border-primary-500 text-primary-600 font-semibold hover:border-primary-500;
  }

  .sidebar-width {
    @apply min-w-[370px];
  }

  .sidebar-item-link {
    @apply relative flex justify-center rounded px-2 py-3 transition duration-200 text-gray-300 hover:text-white hover:bg-gray-600;
  }

  .sidebar-item-link-active {
    @apply text-primary-500 bg-gray-100 hover:text-white hover:bg-gray-800;
  }

  .sidebar-item-link-disabled {
    @apply text-palette-gray cursor-not-allowed opacity-50;
  }

  .sidebar-item-text {
    @apply text-[10px] font-medium tracking-[-0.5px];
  }

  .notification-width {
    @apply min-w-[550px];
  }

  .notification-tab-link {
    @apply flex items-center gap-2 border-b-2 border-transparent px-4 pb-2 text-sm font-medium text-palette-gray hover:border-gray-300 transition duration-100 ease-in justify-center;
  }

  .notification-tab-link-active {
    @apply border-primary-500 text-primary-600 font-semibold;
  }

  .custom-scrollbar {
    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: theme("colors.gray.400") theme("colors.gray.100");

    /* For Webkit browsers */

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background: theme("colors.gray.100");
    }

    &::-webkit-scrollbar-thumb {
      background-color: theme("colors.gray.400");
      border-radius: 4px;

      /* Smooth transition for hover effect */
      transition: background-color 0.3s ease-in-out;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: theme("colors.gray.500");
    }

    /* Hide scrollbar buttons */

    &::-webkit-scrollbar-button {
      display: none;
    }
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 345 6% 13%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

html {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ 및 Edge */
  user-select: none; /* 표준 구문 */
}

body {
  overflow: hidden; /* Hide scrollbars */
}

/* ? 스크롤바 스타일 구역 */
.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ? 체크박스의 내부 원 사이즈 크기를 조절한다. */
[type="radio"]:checked {
  background-size: 130% 130%;
}

/* ? 캘린더 스타일 구역 */
.calendar {
  max-width: 390px; /* 컨테이너의 최대 너비를 설정합니다 */
  margin: auto;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px 20px;
}

.calendar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.weekdays {
  margin-bottom: 10px;
}

.weekdays,
.calendar-grid {
  border-bottom: 1px solid #ddd;
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 7개의 칼럼을 동일한 비율로 설정 */
}

.week-day,
.calendar-day {
  height: 50px; /* 높이 설정 */
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-day {
  /* border: 1px solid #ddd; */
  cursor: pointer;
  transition: background-color 0.2s ease-out;
}

.calendar-day:hover {
  background-color: #eee;
}

.calendar-day.selected {
  transition: background-color 0.2s ease-out;
  background-color: #aee2ff;
  color: black;
}

.calendar-day.start-or-end {
  transition: background-color 0.2s ease-out;
  background-color: #aee2ff;
  color: black;
  font-weight: bold;
}

.calendar-day.disabled,
.disabled {
  pointer-events: none; /* 클릭 불가능 */
  opacity: 0.2; /* 반투명으로 표시 */
  cursor: not-allowed; /* 마우스 커서 변경 */
}

.calendar-day.saturday,
.saturday {
  color: dodgerblue;
}

.calendar-day.sunday,
.sunday {
  color: tomato;
}

.selected-date {
  margin-top: 10px;
  font-size: 14px;
  letter-spacing: -0.5px;
  font-weight: bold;
}

/* ? 검색 버튼 크기 애니메이션 구역 */
@keyframes pulse-scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

/* ? ROI 검색 로딩 인디케이터 구역 */

.load-7 .square {
  animation: loadingG 1.5s cubic-bezier(0.17, 0.37, 0.43, 0.67) infinite;
}

@keyframes loadingG {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(70px, 0) rotate(360deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}
